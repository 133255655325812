<template>
    <div>
        <div class="member_info_container" v-if="userType == '0'">
            <MemberTitle :memberTitle="L['会员信息']"></MemberTitle>
            <div class="member_info">
                <table class="form_item">
                    <!-- <tr>
                        <td>
                            <span class="tag_name">用户头像：</span>
                        </td>
                        <td>
                            <form action="" enctype="multipart/form-data">
                                <label for="file" class="chooseBtn"><img :src="tempSrc" id="show" /></label> -->
                    <!-- <input id="file" class="filepath"  type="file" name="image" size="1"
                                accept="image/gif,image/jpeg,image/gif,image/png"> -->
                    <!-- </form>
                        </td>
                    </tr> -->

                    <!-- <tr>
                    <td></td>
                    <td>{{L['仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。']}}</td>
                </tr> -->
                    <!-- <tr>
                        <td>
                            <span class="tag_name">{{ L["会员名："] }}</span>
                        </td>
                        <td>
                            <el-input v-model="memberNameInput" :disabled="true"></el-input>
                        </td>
                    </tr> -->
                    <tr>
                        <td>
                            <span class="tag_name">真实姓名：</span>
                        </td>
                        <td>
                            <el-input v-model="memberTrueName" :disabled="true" show-word-limit></el-input>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="tag_name">手机号：</span>
                        </td>
                        <td>
                            <el-input v-model="memberMobile" :disabled="true" show-word-limit />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="tag_name">身份证号：</span>
                        </td>
                        <td>
                            <el-input v-model="memberCorpCode" :disabled="true" show-word-limit />
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="member_info_container sld_prise_login" v-if="userType == '1' || userType == '2'">
            <MemberTitle :memberTitle="L['会员信息']"></MemberTitle>
            <div class="reg_box">
                <div class="reg_txt">公司信息</div>
                <div class="reg_con">
                    <div class="regist_box">
                        <div class="regist_tx_inp">企业联系人&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpContactName }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">联系人手机号&nbsp;&nbsp;&nbsp;&nbsp;{{ name }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">企业名称&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpName }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">企业简称&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpNameAs }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">营业执照&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="regist_up_con">
                            <div class="regist_tx_inp">
                                <a :href="urlList.corpImg" target="_blank">查看营业执照</a>
                            </div>
                        </div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">组织代码&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpCode }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="d_textarea">详细地址&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpDetailAddress }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">企业邮箱&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpEmail }}</div>
                    </div>
                </div>
            </div>
            <div class="reg_box">
                <div class="reg_txt">法人信息</div>
                <div class="reg_con">
                    <div class="regist_box">
                        <div class="regist_tx_inp">法人姓名&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpLegalName }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">法人手机号&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpLegalTel }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">身份证正面&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="regist_up_con">
                            <div class="regist_tx_inp">
                                <a :href="urlList.corpImgFront" target="_blank">查看身份证正面</a>
                            </div>
                        </div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">身份证反面&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <div class="regist_up_con">
                            <div class="regist_tx_inp">
                                <a :href="urlList.corpImgBack" target="_blank">查看身份证反面</a>
                            </div>
                        </div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">法人身份证号&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpLegalCode }}</div>
                    </div>
                </div>
            </div>
            <div class="reg_box">
                <div class="reg_txt">开户信息</div>
                <div class="reg_con">
                    <div class="regist_box">
                        <div class="regist_tx_inp">开户银行&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpBankName }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">银行账号&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpBankAccount }}</div>
                    </div>
                    <div class="regist_box">
                        <div class="regist_tx_inp">
                            开户行地址&nbsp;&nbsp;&nbsp;&nbsp;{{ medata.corpBankOpenAddress }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="sld_login_header"></div>
        </div>
    </div>
</template>

<script>
import MemberTitle from "../../components/MemberTitle";

// import axios from 'axios'
import { useRouter, useRoute } from "vue-router";
import { ref, getCurrentInstance, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import { ElMessage, ElPagination, ElInput, ElButton } from "element-plus";
import { apiUrl } from "../../utils/config";
import axios from "axios";
import areaData from "../../assets/area.json";
export default {
    name: "MemberInfo",
    components: {
        ElInput,
        ElButton,
        MemberTitle
    },
    setup() {
        const store = useStore();
        const memberInfo = reactive({ data: store.state.memberInfo });
        const agreeFlag = ref(false); //同意注册协议标识，默认不同意
        const name = ref(""); //用户名
        const route = useRoute();
        const errorMsg = ref(); //错误提示
        const password = ref(""); //密码
        const imgCode = ref(""); //图形验证码
        const timestampVal = ref(""); //时间戳
        const timestampImg = ref(""); //图形验证码时间戳
        const areaDataArray = areaData; //地址
        const cascaderAddr = ref(null); //地址ref
        const form = reactive({ data: {} }); //地址code
        const cascaderProps = { label: "regionName", value: "regionCode" }; //地址数据
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const countDownM = ref(0); //短信验证码倒计时
        const timeOutId = ref(""); //定时器的返回值
        const showCodeImg = ref(""); //图形验证码图片
        const imgCodeKey = ref(""); //图形验证码的key
        const configInfo = ref(store.state.configInfo);
        const defaultImg = ref('this.src="' + require("../../assets/common_top_logo.png") + '"');
        const defaultBgImg = ref('this.src="' + require("../../assets/login_bg.png") + '"');
        const preventFre = ref(false);
        const memberNameInput = ref(""); //会员名
        const memberNickNameInput = ref(""); //会员名称
        const memberCorpCode = ref("");
        const memberMobile = ref("");
        const memberTrueName = ref(""); //会员姓名
        const avatar = ref(""); //用户头像
        const tempSrc = ref("");
        const userType = ref("");
        const sex = ref(0); //性别
        const yearArr = ref(Array.from({ length: 80 }, (v, k) => k).map((item) => new Date().getFullYear() - item)); //生成年份数组-80年间隔
        const year = ref(""); //当前年份
        const monthArr = ref(ref(Array.from({ length: 12 }, (v, k) => k).map((item) => 12 - item))); //生成当月的月数
        const month = ref(""); //当前月分
        let d = new Date(year.value, month.value, 0).getDate(); //生成设置月分的当月天数
        const dayArr = ref(Array.from({ length: d }, (v, k) => k).map((item) => item + 1)); //生成当月天数数组
        const day = ref(""); //当前天

        const changepic = (e) => {
            //上传头像
            let newsrc = getObjectURL(e.target.files[0]);
            let data = new FormData();
            data.append("file", e.target.files[0]);
            data.append("source", "headImg");
            let config = {
                headers: { "Content-Type": "multipart/form-data" }
            };
            let url = apiUrl + "/v3/oss/common/upload";
            if (e.target.files[0].size < Math.pow(1024, 2) * 4) {
                tempSrc.value = newsrc;
                axios.post(url, data, config).then((res) => {
                    if (res.status === 200) {
                        ElMessage.success("上传图片成功");
                        tempSrc.value = res.data.data.url;
                        avatar.value = res.data.data.path;
                    } else {
                        ElMessage.warning("上传图片失败");
                    }
                });
            } else {
                ElMessage.warning("图像应小于4MB");
                tempSrc.value = avatar.value;
            }
        };
        const getObjectURL = (file) => {
            //createObjectURL在前端显示更换后的头像
            var url = null;
            if (window.createObjectURL != undefined) {
                // basic
                url = window.createObjectURL(file);
            } else if (window.URL != undefined) {
                // mozilla(firefox)
                url = window.URL.createObjectURL(file);
            } else if (window.webkitURL != undefined) {
                // webkit or chrome
                url = window.webkitURL.createObjectURL(file);
            }
            return url;
        };

        const getInitData = () => {
            //初始化数据
            proxy.$get("api/shopp/member/detail", { uscUserId: store.state.memberInfo.uscUserId }).then((res) => {
                //旧 v3/member/front/member/memberInfo
                if (res.code == 200) {
                    // sex.value = res.data.gender;
                    // memberNameInput.value = res.data.corpName;
                    memberCorpCode.value = res.data.corpCode;
                    memberMobile.value = res.data.memberMobile;
                    // memberNickNameInput.value = res.data.memberNickName;
                    // tempSrc.value = res.data.memberAvatar;
                    memberTrueName.value = res.data.memberTrueName;
                    // year.value = res.data.memberBirthday ? res.data.memberBirthday.split("-")[0] : "";
                    // month.value = res.data.memberBirthday ? parseInt(res.data.memberBirthday.split("-")[1]) : "";
                    // day.value = res.data.memberBirthday ? parseInt(res.data.memberBirthday.split("-")[2]) : "";
                }
            });
        };

        const memberInfoSave = () => {
            //保存
            // let params = new FormData()
            if (
                !memberNickNameInput.value &&
                typeof memberNickNameInput.value !== "undefined" &&
                memberNickNameInput.value != 0
            ) {
                ElMessage.warning("请输入昵称");
                return;
            }
            if (!memberNickNameInput.value.trim()) {
                ElMessage.warning("请输入昵称");
                return;
            }

            if (!avatar.value) {
                avatar.value = tempSrc.value;
            }

            let birthday = [year.value, month.value, day.value]
                .map((item) => (item.toString().length < 2 ? "0" + item : item))
                .join("-");
            let params = {
                gender: sex.value,
                memberAvatar: avatar.value,
                memberBirthday: birthday,
                memberName: memberNameInput.value,
                memberNickName: memberNickNameInput.value.trim()
            };
            if (memberTrueName.value) {
                params.memberTrueName = memberTrueName.value.trim();
                let reg =
                    /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im;
                if (reg.test(memberTrueName.value)) {
                    ElMessage.warning("真实姓名包含特殊字符，请重新输入");
                    return;
                }
            }

            proxy.$post("/v3/member/front/member/updateInfo", params).then((res) => {
                if (res.state == 200) {
                    ElMessage.success(res.msg);
                    getInitData();
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };

        watch(year, () => {
            //监听设置的年，如果是现在的年份，则月和天不会超过今天的年月日
            let time = new Date();
            if (year.value == time.getFullYear()) {
                dayArr.value = dayArr.value.filter((item) => item <= time.getDate());
                monthArr.value = monthArr.value.filter((item) => item <= time.getMonth() + 1);
            } else {
                dayArr.value = Array.from({ length: d }, (v, k) => k).map((item) => item + 1); //生成当月天数数组
                monthArr.value = Array.from({ length: 12 }, (v, k) => k).map((item) => 12 - item); //生成当月的月数
            }
        });

        watch(month, () => {
            let time = new Date();
            d = new Date(year.value, month.value, 0).getDate();
            dayArr.value = Array.from({ length: d }, (v, k) => k).map((item) => item + 1); //生成当月天数数组
            if (year.value == time.getFullYear() && month.value == time.getMonth() + 1) {
                dayArr.value = dayArr.value.filter((item) => item <= time.getDate());
            } else {
                dayArr.value = Array.from({ length: d }, (v, k) => k).map((item) => item + 1); //生成当月天数数组
            }
        });
        const companyList = reactive([
            //公司类型
            {
                value: "公司"
            },
            {
                value: "个人"
            }
        ]);
        const medata = reactive(
            //数据
            {
                corpAddressAll: "", //省市区组合
                corpBankAccount: "", //银行账号
                corpBankName: "", //开户银行
                corpBankOpenAddress: "", //开户地址
                enterpriseCityCode: "", //城市编码
                corpCode: "", //企业组织代码
                corpContactName: "", //企业联系人姓名
                corpDetailAddress: "", //详细地址
                enterpriseDistrictCode: "", //区县编码
                corpEmail: "", //邮箱
                enterpriseImg: "", //企业营业执照
                corpLegalCode: "", //法人身份证
                enterpriseLegalCodeImgBack: "", //法人身份证反面
                enterpriseLegalCodeImgFront: "", //法人身份证正面
                corpLegalName: "", //法人姓名
                corpLegalTel: "", //法人手机
                corpName: "", //	企业名称
                corpNameAs: "", //企业简称
                enterpriseProvinceCode: "", //省份编码
                enterpriseType: "" //公司类型
            }
        );
        let urlList = reactive({
            positive: "",
            corpImg: "",
            identityone: "",
            corpImgFront: "",
            identitytwo: ""
        });
        const uploadFileDOM = ref(null); //ref上传图片
        const uploadFileDOMone = ref(null); //ref上传图片
        const uploadFileDOMtwo = ref(null); //ref上传图片

        // 唤起上传
        const uploadFile = (flag) => {
            if (flag == "positive") {
                uploadFileDOM.value.click();
            }
            if (flag == "identityone") {
                uploadFileDOMone.value.click();
            }
            if (flag == "identitytwo") {
                uploadFileDOMtwo.value.click();
            }
        };

        // 上传图片事件
        const upload_img = (e, path, img) => {
            let data = new FormData();
            data.append("file", e.target.files[0]);
            data.append("source", "headImg");
            let config = {
                headers: { "Content-Type": "multipart/form-data" }
            };
            let url = apiUrl + "/v3/oss/common/upload";
            if (e.target.files[0].size < Math.pow(1024, 2) * 4) {
                axios.post(url, data, config).then((res) => {
                    if (res.status === 200) {
                        ElMessage.success("上传图片成功");
                        urlList[img] = res.data.data.url;
                        urlList[path] = res.data.data.path;
                    } else {
                        ElMessage.warning("上传图片失败");
                    }
                });
            } else {
                ElMessage.warning("图像应小于4MB");
                tempSrc.value = avatar.value;
            }
        };
        //点击注册
        const login = () => {
            if (!medata.corpContactName.trim()) {
                ElMessage.warning("请输入企业联系人姓名");
                return;
            }
            if (!name.value.trim()) {
                ElMessage.warning("请输入手机号");
                return;
            } else {
                let checkMobile = proxy.$checkPhone(name.value);
                if (checkMobile !== true) {
                    ElMessage.warning("请输入正确的手机号");
                    return false;
                }
            }
            if (!imgCode.value.trim()) {
                ElMessage.warning("请输入图形验证码");
                return;
            }
            if (!password.value.trim()) {
                ElMessage.warning("请输入短信验证码");
                return;
            } else {
                let passwords = proxy.$checkSmsCode(password.value);
                if (passwords !== true) {
                    ElMessage.warning("请输入正确的短信验证码");
                    return false;
                }
            }
            if (!medata.corpName.trim()) {
                ElMessage.warning("请输入企业名称");
                return;
            }
            if (!medata.corpNameAs.trim()) {
                ElMessage.warning("请输入企业简称");
                return;
            }
            if (urlList.positive.length == 0) {
                ElMessage.warning("请上传营业执照");
                return;
            }
            if (!medata.corpCode.trim()) {
                ElMessage.warning("请输入企业组织代码");
                return;
            }
            if (!medata.enterpriseType.trim()) {
                ElMessage.warning("请选择公司类型");
                return;
            }
            if (!medata.corpLegalName.trim()) {
                ElMessage.warning("请输入法人姓名");
                return;
            }
            // if(!medata.enterpriseLegalCode.trim()){
            //     ElMessage.warning('请输入法人身份证')
            //     return
            // }else {
            //     let LegalCode = proxy.$checkIdentity(medata.enterpriseLegalCode);
            //     if (LegalCode !== true) {
            //         ElMessage.warning('请输入正确的法人身份证')
            //         return false;
            //     }
            // }
            if (!medata.corpLegalTel.trim()) {
                ElMessage.warning("请输入法人手机号");
                return;
            } else {
                let checkMobile = proxy.$checkPhone(medata.corpLegalTel);
                if (checkMobile !== true) {
                    ElMessage.warning("请输入正确的手机号");
                    return false;
                }
            }
            if (!medata.corpLegalCode.trim()) {
                ElMessage.warning("请输入法人身份证");
                return;
            } else {
                let LegalCode = proxy.$checkIdentity(medata.corpLegalCode);
                if (LegalCode !== true) {
                    ElMessage.warning("请输入正确的法人身份证");
                    return false;
                }
            }
            if (urlList.identityone.length == 0) {
                ElMessage.warning("请上传法人身份证正面");
                return;
            }
            if (urlList.identitytwo.length == 0) {
                ElMessage.warning("请上传法人身份证反面");
                return;
            }

            if (!form.data.area) {
                ElMessage.warning("请选择地址");
                return;
            }
            if (!medata.corpDetailAddress.trim()) {
                ElMessage.warning("请输入详细地址");
                return;
            }
            if (!medata.corpBankName.trim()) {
                ElMessage.warning("请输入开户银行");
                return;
            }
            if (!medata.corpBankAccount.trim()) {
                ElMessage.warning("请输入银行账号");
                return;
            }
            if (!medata.corpBankOpenAddress.trim()) {
                ElMessage.warning("请输入开户地址");
                return;
            }
            if (!medata.corpEmail.trim()) {
                ElMessage.warning("请输入企业邮箱");
                return;
            } else {
                let Email = proxy.$checkEmail(medata.corpEmail);
                if (Email !== true) {
                    ElMessage.warning("请输入正确的企业邮箱");
                    return false;
                }
            }
            if (!medata.corpDetailAddress.trim()) {
                ElMessage.warning("请输入详细地址");
                return;
            }

            if (!agreeFlag.value) {
                ElMessage.warning("请同意用户注册协议及隐私政策");
                return;
            }
            medata.enterpriseProvinceCode = form.data.area[0];
            medata.enterpriseCityCode = form.data.area[1];
            medata.enterpriseDistrictCode = form.data.area[2];
            medata.enterpriseImg = urlList.positive;
            medata.enterpriseLegalCodeImgFront = urlList.identityone;
            medata.enterpriseLegalCodeImgBack = urlList.identitytwo;
            medata.enterpriseAddressAll = cascaderAddr.value.getCheckedNodes()[0].pathLabels.join("");
            let param = {};
            param.phone = name.value;

            param.code = password.value;
            param.verifyCode = imgCode.value;
            param.verifyKey = imgCodeKey.value;
            param.source = 1;
            param.memberExtendDto = medata;
            param.memberExtendDto.memberMobile = name.value;
            console.log(cascaderAddr.value.getCheckedNodes()[0].pathLabels.join(""));
            // 如果未登录vuex中有购物车数据，需同步到该用户
            let cartInfo = [];
            let cartListData = store.state.cartListData;
            if (JSON.stringify(cartListData) != "{}") {
                cartListData.storeCartGroupList.map((cartListItem) => {
                    cartListItem.promotionCartGroupList.map((promotItem) => {
                        promotItem.cartList.map((cartItem) => {
                            cartInfo.push({
                                productId: cartItem.productId,
                                buyNum: cartItem.buyNum
                            });
                        });
                    });
                });
            }
            param.cartInfo = JSON.stringify(cartInfo);
            let url = "";
            if (route.query.memberId) {
                url = "v3/frontLogin/oauth/register?type=1&memberId=" + Number(route.query.memberId);
            } else {
                url = "v3/frontLogin/oauth/register?type=1";
            }
            proxy.$post(url, param, "application/x-www-form-urlencoded;charset=UTF-8").then((res) => {
                if (res.state == 200) {
                    if (res.data.memberType == "审核中") {
                        router.replace({
                            path: "/registrationReview",
                            query: {
                                memberId: route.query.memberId
                            }
                        });
                    } else {
                        //将用户信息存缓存，并跳转到首页
                        localStorage.setItem("access_token", res.data.access_token);
                        localStorage.setItem("refresh_token", res.data.refresh_token);
                        localStorage.setItem("time", new Date().getTime().toString()); //存储refresh_token更新时间
                        //获取用户信息，并同步信息到vuex
                        proxy
                            .$get("api/shopp/member/detail", { uscUserId: store.state.memberInfo.uscUserId })
                            .then((res) => {
                                if (res.code == 200) {
                                    //清空购物车store
                                    proxy.$sldStatEvent({ behaviorType: "reg" });
                                    store.commit("updateCartListData", {});
                                    store.commit("updateMemberInfo", res.data);
                                    router.back();
                                }
                            });
                    }
                } else {
                    //提示错误
                    getImgCode();
                    ElMessage.warning(res.msg);
                    errorMsg.value = res.msg;
                }
            });
            return;
            router.push({
                path: "/registrationReview"
            });
        };
        //清空输入框内容
        const clearInputVal = (type) => {
            if (type == "name") {
                name.value = "";
            } else if (type == "password") {
                password.value = "";
            }
        };

        //获取短信验证码
        const getSmsCode = () => {
            if (preventFre.value) {
                return;
            } else if (countDownM.value) {
                return;
            }
            preventFre.value = true;
            let checkMobile = proxy.$checkPhone(name.value);
            let checkImgCode = proxy.$checkImgCode(imgCode.value);
            if (checkMobile !== true) {
                errorMsg.value = checkMobile;
                preventFre.value = false;
            } else if (checkImgCode !== true) {
                ElMessage.warning("图形验证码输入错误");
                // errorMsg.value = checkImgCode;
                preventFre.value = false;
            } else {
                let param = {};
                timestampVal.value = new Date().getTime();
                param.mobile = name.value;
                param.timestamp = timestampVal.value;
                param.verifyCode = imgCode.value;
                param.verifyKey = imgCodeKey.value;
                proxy.$get("api/shopp/member/send/sms/code", param).then((res) => {
                    if (res.code == 200) {
                        countDownM.value = 60;
                        countDown();
                        preventFre.value = false;
                    } else {
                        //提示错误
                        getImgCode();
                        errorMsg.value = res.msg;
                        preventFre.value = false;
                    }
                });
            }
        };
        //倒计时
        const countDown = () => {
            countDownM.value--;
            if (countDownM.value == 0) {
                preventFre.value = false;
                clearTimeout(timeOutId.value);
            } else {
                timeOutId.value = setTimeout(countDown, 1000);
            }
        };
        //获取图形验证码
        const getImgCode = () => {
            timestampImg.value = new Date().getTime();
            proxy.$get("api/shopp/member/create/image/code", { timestamp: timestampImg.value }).then((res) => {
                if (res.code == 200) {
                    showCodeImg.value = res.data;
                }
            });
        };
        //验证图形验证码
        const verifyBlur = () => {
            let params = {
                timestamp: timestampImg.value,
                code: imgCode.value
            };
            proxy.$get("api/shopp/member/image/code/valid", params).then((res) => {
                if (res.code != 200) {
                    errorMsg.value = "图形验证码错误";
                    getImgCode();
                }
            });
        };
        //是否同意用户注册协议
        const agree = () => {
            agreeFlag.value = !agreeFlag.value;
        };
        getImgCode(); //获取图形验证码
        //通过replace方式跳转页面
        const goToPage = (type) => {
            router.replace({
                path: type
            });
        };

        watch([name, imgCode, password], () => {
            password.value = password.value.substring(0, 20);
            imgCode.value = imgCode.value.substring(0, 4);
            name.value = name.value.substring(0, 11);
            if (password.value || imgCode.value || name.value) {
                errorMsg.value = "";
            }
        });

        // 审核失败获取数据
        const getReapply = () => {
            let param = {};
            param.uscUserId = memberInfo.data.uscUserId;
            proxy.$get("api/shopp/member/detail", param).then((res) => {
                if (res.code == 200) {
                    for (var i in medata) {
                        medata[i] = res.data.corp[i];
                    }
                    form.data.area = [];
                    form.data.area[0] = res.data.corp.enterpriseProvinceCode;
                    form.data.area[1] = res.data.corp.enterpriseCityCode;
                    form.data.area[2] = res.data.corp.enterpriseDistrictCode;
                    name.value = res.data.memberMobile;
                    urlList.corpImg = res.data.corp.corpImg;
                    urlList.positive = res.data.corp.enterpriseImg;
                    urlList.corpImgFront = res.data.corp.corpImgFront;
                    urlList.identityone = res.data.corp.enterpriseLegalCodeImgFront;
                    urlList.corpImgBack = res.data.corp.corpImgBack;
                    urlList.identitytwo = res.data.corp.enterpriseLegalCodeImgBack;
                }
            });
        };

        onMounted(() => {
            console.log(memberInfo.data);
            // document.body.classList.remove('el-popup-parent--hidden');
            if (memberInfo.data.memberUserType == "1" || memberInfo.data.memberUserType == "2") {
                userType.value = memberInfo.data.memberUserType;
                getReapply();
            } else {
                userType.value = memberInfo.data.memberUserType;
                getInitData();
            }
        });
        return {
            L,
            login,
            name,
            password,
            timestampVal,
            timestampImg,
            verifyBlur,
            clearInputVal,
            errorMsg,
            getSmsCode,
            countDownM,
            imgCode,
            showCodeImg,
            imgCodeKey,
            getImgCode,
            agreeFlag,
            agree,
            goToPage,
            configInfo,
            defaultImg,
            defaultBgImg,
            urlList,
            upload_img,
            uploadFileDOM,
            uploadFile,
            medata,
            companyList,
            areaDataArray,
            cascaderProps,
            cascaderAddr,
            form,
            uploadFileDOMtwo,
            uploadFileDOMone,
            memberNameInput,
            memberNickNameInput,
            sex,
            yearArr,
            year,
            monthArr,
            month,
            dayArr,
            day,
            avatar,
            memberInfoSave,
            changepic,
            tempSrc,
            memberTrueName,
            userType,
            memberMobile,
            memberCorpCode
        };
    }
};
</script>
<style lang="scss" scoped>
.sld_prise_login {
    input::input-placeholder {
        color: #bbbbbb;
    }
    ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #bbbbbb;
    }
    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #bbbbbb;
    }
    ::-moz-placeholder {
        /* Mozilla Firefox 19  */
        color: #bbbbbb;
    }
    :-ms-input-placeholder {
        /* Internet Explorer 10  */
        color: #bbbbbb;
    }
    .sld_login_header {
        background: #fff;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);

        .content {
            align-items: center;
            width: 850px;
            margin: auto;

            .l_logo {
                cursor: pointer;
                display: flex;
                margin-right: 70%;

                .img {
                    display: inline-block;
                    vertical-align: top;
                    max-width: 190px;
                    max-height: 43px;
                }

                .text {
                    font-size: 0;
                    display: inline-block;
                    vertical-align: top;
                    line-height: 48px;
                    margin-left: 5px;
                }
            }

            .r_register_wrap {
                font-size: 14px;

                .go_register_btn {
                    font-size: 13px;
                    display: inline-block;
                    padding: 6px 22px;
                    background: $colorMain;
                    color: #fff;
                    border-radius: 30px;
                    margin-left: 7px;
                    font-family: Microsoft YaHei;
                    height: 30px;
                    line-height: 17px;
                }
            }
        }
    }

    .sld_login_content {
        width: 100%;
        height: 100% !important;
        position: relative;
        // background: #F7F5F7;
        .bg {
            position: absolute;
            margin: auto;
            width: 1920px;
            height: 100%;
            margin-left: -960px;
            left: 50%;
            overflow: hidden;
            display: block;
        }
        .login {
            width: 750px;
            height: 100%;
            margin: auto;
            z-index: 99;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .prise_regise {
                width: 1108px;
                // height: 1045px;d
                background: #ffffff;
                box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.05);
                // margin: 51px 0;
                box-sizing: border-box;
                .prise_reg_tit {
                    width: 100%;
                    height: 60px;
                    background: #f30213;
                    font-size: 18px;
                    font-weight: 500;
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .prise_reg_box {
                    //   padding-top: 47px;
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 62px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .prise_reg_left {
                        width: 472px;
                        .prise_reg_left:nth-child(1) {
                            margin-top: 0px;
                        }
                    }
                    .regist_box {
                        margin-top: 20px;
                        width: 472px;
                        height: 42px;
                        line-height: 42px;
                        background: #ffffff;
                        border-radius: 2px;
                        // border: 1px solid #DCDCDC;
                        display: flex;
                        align-items: center;
                        position: relative;
                        .send_code {
                            position: absolute;
                            right: 0;
                            top: 0;
                            background: #f9f9f9;
                            border-left: 0;
                            width: 80px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            color: #000;
                        }
                        .regist_img {
                            height: 100%;
                            width: 55px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            &::after {
                                content: "";
                                width: 1px;
                                height: 32px;
                                background-color: #bbb;
                                position: absolute;
                                right: 0;
                            }
                            img {
                                width: 30px;
                                height: 30px;
                            }
                        }
                        input {
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .regist_tx_inp {
                            outline: none;
                            border: none;
                            margin-left: 12px;
                            height: 100%;
                            //   width: 350px;
                        }
                        .close {
                            width: 16px;
                            height: 16px;
                            position: absolute;
                            right: 20px;
                        }
                    }
                    .prise_reg_rhght {
                        width: 472px;
                        .prise_reg_left:nth-child(1) {
                            margin-top: 0px;
                        }
                        .regist_box_right {
                            height: 194px;
                            .regist_img {
                                &::after {
                                    content: "";
                                    width: 1px;
                                    height: 181px;
                                    background-color: #bbb;
                                    position: absolute;
                                    right: -5px;
                                }
                            }
                            .regist_con {
                                height: 100%;
                                width: 100%;
                                .d_textarea {
                                    resize: none;
                                    width: 391px;
                                    height: 90px;
                                    background: #f9f9f9;
                                    border-radius: 3px;
                                    border: none;
                                    margin-top: 18px;
                                    padding: 10px;
                                    font-size: 14px;
                                    font-weight: 400;
                                }
                                .reg_rig_inp {
                                    font-size: 14px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    height: 32px;
                                    margin-top: 2px;
                                    outline: none;
                                    border: none;
                                }
                            }
                        }

                        .regist_box_up {
                            height: 132px;
                            .regist_img {
                                &::after {
                                    content: "";
                                    width: 1px;
                                    height: 122px;
                                    background-color: #bbb;
                                    position: absolute;
                                    right: -6px;
                                }
                            }
                            .regist_up_con {
                                width: 100%;
                                height: 100%;
                                padding-left: 41px;
                                padding-top: 15px;
                                display: flex;
                                .upload_con {
                                    width: 130px;
                                    height: 82px;
                                    background: #fdfdfd;
                                    border-radius: 2px;
                                    border: 1px dashed #dcdcdc;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
                                    justify-content: center;
                                    cursor: pointer;
                                    .jia {
                                        width: 35px;
                                        height: 35px;
                                    }
                                    .upload_p {
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: #666666;
                                        margin-top: 10px;
                                    }
                                }
                                .upload_ti {
                                    font-size: 14px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #bbbbbb;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .prise_reg_left {
                        .regist_box {
                            .regis_lei {
                                padding-left: 12px;
                                padding-right: 22px;
                                // font-size: 14px;
                                font-weight: 400;
                                // color: #BBBBBB;
                            }
                        }
                        .regist_box_up {
                            height: 132px;
                            .regist_img {
                                &::after {
                                    content: "";
                                    width: 1px;
                                    height: 122px;
                                    background-color: #bbb;
                                    position: absolute;
                                    right: -6px;
                                }
                            }
                            .regist_up_con {
                                width: 100%;
                                height: 100%;
                                padding-left: 41px;
                                padding-top: 15px;
                                display: flex;
                                .upload_con {
                                    width: 130px;
                                    height: 82px;
                                    background: #fdfdfd;
                                    border-radius: 2px;
                                    border: 1px dashed #dcdcdc;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
                                    justify-content: center;
                                    cursor: pointer;
                                    .jia {
                                        width: 35px;
                                        height: 35px;
                                    }
                                    .upload_p {
                                        font-size: 12px;
                                        font-weight: 400;
                                        color: #666666;
                                        margin-top: 10px;
                                    }
                                }
                                .upload_ti {
                                    font-size: 14px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #bbbbbb;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
            // 立即注册
            .foot_box {
                margin-top: 66px;
                margin-bottom: 20px;
                position: relative;
                .register_btn_p {
                    height: 46px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    position: absolute;
                    right: 289px;
                    top: 50%;
                    cursor: default;
                    transform: translateY(-50%);
                    p {
                        color: #fa1e01;
                        cursor: pointer;
                    }
                }
            }
            .register_btn {
                width: 270px;
                height: 46px;
                background: #fa1e01;
                border-radius: 2px;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                cursor: pointer;
            }
        }
    }

    // 隐私协议
    .agree_wrap {
        margin: 0 auto;
        margin-top: 11px;
        height: 14px;
        line-height: 14px;
        color: #999;
        padding-left: 2px;
        position: relative;
        cursor: pointer;
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        width: 261px;
        .agree_selected {
            color: #fff;
            position: absolute;
            top: 1px;
            left: 3px;
            z-index: 2;
            font-size: 13px;
        }

        .checkbox {
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            outline: none;
            -webkit-appearance: none;
            background: none;
            border: none;
            box-sizing: border-box;
            cursor: pointer;
            box-shadow: none;
            position: relative;

            &.checked {
                &:before {
                    background: $colorMain;
                    border-color: $colorMain;
                }
            }

            &:before {
                border: 1px solid #ddd;
                background: #fff;
                z-index: 1;
                position: relative;
                top: 0;
                left: 0;
                content: " ";
                display: block;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
            }
        }

        .text {
            margin-left: 5px;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;

            .agreement {
                color: #ff2f2f;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .up_img {
        width: 130px;
        height: 79px;
        object-fit: contain;
    }
}

.reg_box {
    background-color: #fff;
    // width: 850px;
}
.reg_txt {
    padding: 30px;
    padding-top: 30px;
    font-size: 16px;
    font-weight: 700;
}
.reg_con {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
}
.regist_box {
    width: 45%;
    display: flex;
    height: 50px;
    margin-left: 30px;
}
.regist_tx_inp a {
    color: blue !important;
}

.el-popup-parent--hidden {
    overflow: visible;
}
</style>
<style lang="scss">
@import "../../style/member/info.scss";

::v-deep.el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 302px;
}

.el-radio-button__inner,
.el-radio-group {
    display: flex;
}
.sld_prise_login {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .regist_con {
        padding-left: 14px;
        padding-top: 14px;
        .el-select {
            width: 391px;
            height: 30px;
            background: #f9f9f9;
            border-radius: 3px;
            .el-input__inner {
                border: none;
                background: #f9f9f9;
            }
        }
    }
    .prise_reg_left {
        .el-select {
            width: 313px;
            // height: 30px;
            background: #f9f9f9;
            border-radius: 3px;
            .el-input__inner {
                border: none;
                height: 30px;
                background: #f9f9f9;
            }
            .el-input__suffix {
                top: -5px;
            }
        }
    }
    .el-cascader {
        .el-input__inner {
            width: 391px;
        }
    }
}
</style>
